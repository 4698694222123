<template lang="pug">
.main-wrapper.caixas-salvar
    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else)
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub /
                        <router-link :to='{ name: "transferencias" }'> Transferências</router-link> /
                        <b>{{  model.id ? 'Visualizar' : 'Adicionar' }}</b>

        form(@submit.prevent='handleSubmit()')
            ProgressBar(v-if='waitingForm' mode="indeterminate")
            div(v-else)
                Panel(:header="`${ model.id ? 'Visualizar' : 'Adicionar' } transferência`")
                    .p-grid.p-fluid.p-align-start(style='margin: 0 auto; max-width: 500px;')

                        .p-col-12(v-if='!model.ie_tipo')
                            Message(:severity='"warn"' :closable='false') Escolha um tipo para poder preencher as demais informações.

                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ie_tipo.$error }")
                            label.form-label Tipo de transferência:
                            SelectButton(v-model='$v.model.ie_tipo.$model' :options='options.tipoOption' :disabled='model.id' optionValue='value' dataKey='value' optionLabel='label' @input='getOrigemDestino()')
                            .feedback--errors(v-if='submitted && $v.model.ie_tipo.$error')
                                .form-message--error(v-if="!$v.model.ie_tipo.required") <b>Tipo de transferência</b> é obrigatório.

                        .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.nr_valor.$error }")
                            label.form-label Valor:
                            .p-inputgroup
                                span.p-inputgroup-addon R$
                                currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                                    v-model='$v.model.nr_valor.$model' locale='pt-BR' :auto-decimal-mode='true' :disabled='!model.ie_tipo || model.id')
                            .feedback--errors(v-if='submitted && $v.model.nr_valor.$error')
                                .form-message--error(v-if="!$v.model.nr_valor.required") <b>Valor</b> é obrigatório.
                                .form-message--error(v-if="!$v.model.nr_valor.minValue") <b>O valor</b> precisa ser maior que <b> 0 </b>.

                        .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.dt_transferencia.$error }")
                            label.form-label Data:
                            .p-inputgroup
                                Calendar( v-model='$v.model.dt_transferencia.$model' dateFormat="dd/mm/yy" :locale="ptbr"
                                    :manualInput='false' placeholder='DD/MM/YYYY' :disabled='!model.ie_tipo || model.id')
                            .feedback--errors(v-if='submitted && $v.model.dt_transferencia.$error')
                                .form-message--error(v-if="!$v.model.dt_transferencia.required") <b>Data</b> é obrigatório.

                        .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.cd_origem.$error }")
                            label.form-label Origem:
                            Dropdown(v-model='$v.model.cd_origem.$model' :options='options.origemOption' :disabled='!model.ie_tipo || model.id'
                                dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' :filter='true' )
                            .feedback--errors(v-if='submitted && $v.model.cd_origem.$error')
                                .form-message--error(v-if="!$v.model.cd_origem.required") <b>Origem</b> é obrigatório.

                        .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.cd_destino.$error }")
                            label.form-label Destino:
                            Dropdown(v-model='$v.model.cd_destino.$model' :options='options.destinoOption' :disabled='!model.ie_tipo || model.id'
                                dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' :filter='true')
                            .feedback--errors(v-if='submitted && $v.model.cd_destino.$error')
                                .form-message--error(v-if="!$v.model.cd_destino.required") <b>Destino</b> é obrigatório.

                        .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_plano_contas.$error }")
                            label.form-label Conta Financeira:
                            Dropdown(v-model='$v.model.cd_plano_contas.$model' :options='options.contaFinanceiraOption' :disabled='!model.ie_tipo || model.id'
                                dataKey='value' optionLabel='label' optionValue='value' placeholder='Selecione' :filter='true')
                            .feedback--errors(v-if='submitted && $v.model.cd_plano_contas.$error')
                                .form-message--error(v-if="!$v.model.cd_plano_contas.required") <b>Conta financeira</b> é obrigatório.

                        .p-col-12
                            label.form-label Observação:
                            Textarea(v-model='model.ds_observacao' :autoResize="true" rows="5" :disabled='!model.ie_tipo || model.id')

                        .p-col-12.mt-2(v-if="model.id")
                            .p-grid
                                .p-col-12.p-md-6.aux-2
                                    span <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                .p-col-12.p-md-6.aux-2
                                    span <b>Data da criação: </b> {{ model.dt_criado_f }}
                        .p-col-12.mt-2
                            Button(v-if='!model.id' label='Salvar' type="submit")
</template>

<style lang="scss">
    .caixas-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import MultiSelect from 'primevue/multiselect'
    import Calendar from 'primevue/calendar'
    import Textarea from 'primevue/textarea'
    import Message from 'primevue/message'

    import { pCalendarLocale_ptbr } from './../../utils'
    import { Caixas, ContasBancarias, DominioValor, Transferencias, PlanoContas } from './../../middleware'
    import { required, minValue } from 'vuelidate/lib/validators'
    import moment from "moment"

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Calendar, Textarea,
                InputMask, SelectButton, Dropdown, Tooltip, MultiSelect, Message
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true

            this.getContaFinanceira()
            this.getDominio()
           
            let id = null
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            if (id) {
                Transferencias.find(id).then(response => {
                    if (response.status == 200) {
                        let keys = Object.keys(this.model)

                        this.model.ie_tipo = response.data.ie_tipo
                        this.getOrigemDestino()
                        
                        keys.forEach(key => {
                            if (key.substr(0, 3) == 'nr_') this.model[key] = parseInt(response.data[key])
                            else if (key !== "ie_tipo") this.model[key] = response.data[key]
                        })
                        
                        this.model.id = id
                        this.model.dt_transferencia = moment(response.data.dt_transferencia).format('DD/MM/YYYY')
                        this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                        if (response.data.dt_atualizado) {
                            this.model.nm_usuario_edi = response.data.nm_usuario_edi
                            this.model.dt_atualizado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                        }
                    }
                    this.waiting = false
                })
            } else this.waiting = false     
        },
        data () {
            return {
                model: {
                    ie_tipo: null,
                    nr_valor: null,
                    dt_transferencia: null,
                    cd_origem: null,
                    cd_destino: null,
                    cd_plano_contas: null,
                    ds_observacao: '',
                },
                options: {
                    tipoOption: [],
                    origemOption: [],
                    destinoOption: [],
                    contaFinanceiraOption: [],
                },
                ptbr: pCalendarLocale_ptbr,
                waiting: true,
                waitingForm: false,
                submitted: false
            }
        },
        validations () {
            let v = {
                model: {
                    ie_tipo: { required },
                    nr_valor: { 
                        required,
                        minValue: minValue(0.01),
                    },
                    dt_transferencia: { required },
                    cd_origem: { required },
                    cd_destino: { required },
                    cd_plano_contas: { required },
                }
            }
            
            return v
        },
        methods: {
            getOrigemDestino() {
                this.options.origemOption = []
                this.options.destinoOption = []
                this.model.cd_origem = null
                this.model.cd_destino = null
                switch (this.model.ie_tipo) {
                    case "CC":
                        Caixas.findAll({ie_tipo: 'S', ie_usuario: true, ie_status: true}).then(response => {
                            if (([200, 201]).includes(response.status)) {
                                response.data.forEach(i => {
                                    this.options.origemOption.push({
                                        value: i.id,
                                        text: i.nm_caixa,
                                    })
                                })
                            } else {
                                this.$toast.error("Houve um problema ao carregar origem e destino.", { duration: 3000 });
                            }
                        })
                        Caixas.findAll({ie_tipo: 'S', ie_status: true}).then(response => {
                            if (([200, 201]).includes(response.status)) {
                                response.data.forEach(i => {
                                    this.options.destinoOption.push({
                                        value: i.id,
                                        text: i.nm_caixa,
                                    })
                                })
                            } else {
                                this.$toast.error("Houve um problema ao carregar origem e destino.", { duration: 3000 });
                            }
                        })
                        break;
                    case "CB":
                        Caixas.findAll({ie_tipo: 'S', ie_usuario: true, ie_status: true}).then(response => {
                            if (([200, 201]).includes(response.status)) {
                                response.data.forEach(i => {
                                    this.options.origemOption.push({
                                        value: i.id,
                                        text: i.nm_caixa,
                                    })
                                })
                            } else {
                                this.$toast.error("Houve um problema ao carregar origem.", { duration: 3000 });
                            }
                        })
                        ContasBancarias.findAll().then(response => {
                            if (([200, 201]).includes(response.status)) {
                                response.data.forEach(i => {
                                    this.options.destinoOption.push({
                                        value: i.id,
                                        text: i.ds_codigo.concat( "-", i.nm_banco, "-", i.nr_agencia, "-", i.nr_conta ),
                                    })
                                })
                            } else {
                                this.$toast.error("Houve um problema ao carregar destino.", { duration: 3000 });
                            }
                        })
                        break;
                    case "BB":
                        ContasBancarias.findAll().then(response => {
                            if (([200, 201]).includes(response.status)) {
                                response.data.forEach(i => {
                                    this.options.destinoOption.push({
                                        value: i.id,
                                        text: i.ds_codigo.concat( "-", i.nm_banco, "-", i.nr_agencia, "-", i.nr_conta ),
                                    })
                                    this.options.origemOption.push({
                                        value: i.id,
                                        text: i.ds_codigo.concat( "-", i.nm_banco, "-", i.nr_agencia, "-", i.nr_conta ),
                                    })
                                })
                            } else {
                                this.$toast.error("Houve um problema ao carregar origem e destino.", { duration: 3000 });
                            }
                        })
                        break;
                    case "BC":
                        Caixas.findAll({ie_tipo: 'S', ie_status: true}).then(response => {
                            if (([200, 201]).includes(response.status)) {
                                response.data.forEach(i => {
                                    this.options.destinoOption.push({
                                        value: i.id,
                                        text: i.nm_caixa,
                                    })
                                })
                            } else {
                                this.$toast.error("Houve um problema ao carregar destino.", { duration: 3000 });
                            }
                        })
                        ContasBancarias.findAll().then(response => {
                            if (([200, 201]).includes(response.status)) {
                                response.data.forEach(i => {
                                    this.options.origemOption.push({
                                        value: i.id,
                                        text: i.ds_codigo.concat( "-", i.nm_banco, "-", i.nr_agencia, "-", i.nr_conta ),
                                    })
                                })
                            } else {
                                this.$toast.error("Houve um problema ao carregar origem.", { duration: 3000 });
                            }
                        })
                        break;
                }
            },
            getContaFinanceira () {
                PlanoContas.findAll({ie_tipo: 'A'}).then(response => {
                    this.options.contaFinanceiraOption = [{value: null, label: 'Selecione'}]
                    if (([200, 201]).includes(response.status)) {
                        response.data.forEach(i => {
                            this.options.contaFinanceiraOption.push({
                                value: i.id,
                                label: i.ds_descricao,
                            })
                        })
                    } else {
                        this.$toast.error("Houve um problema ao carregar as contas financeiras.", { duration: 3000 });
                    }
                })
            },
            getDominio () {
                DominioValor.findAll({ds_mnemonico: 'TIPO_TRANSFERENCIA'}).then((response) => {
					if(response.status === 200) {
                        response.data['TIPO_TRANSFERENCIA'].valores.forEach(item => {
							this.options.tipoOption.push({label: item.ds_valor, value: item.ie_valor})
						})
					}
				})
            },
            handleSubmit () {
                this.submitted = true
                
                this.$v.$touch()
                if (this.$v.$invalid) return 0
                
                let dataSend = Object.assign({}, this.model)
                dataSend["dt_transferencia"] = moment(this.model.dt_transferencia, 'DD/MM/YYYY').utcOffset('-03:00').format('YYYY-MM-DD')
                
                this.waitingForm = true
                
                Transferencias.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'transferencias' })
                        this.$toast.success('Transferência REALIZADA com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                    this.waitingForm = false
                })
            }
         }
      }
</script>